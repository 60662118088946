import client from "../config/axios"

export type GuildAnalytics = {
  global: FAQAnalytics,
  faqs: {
    [faqId: string]: FAQAnalytics
  }
  answer_types: {
    [key in AnalyticsAnswerType]: FAQAnalytics
  }
}

export type AnalyticsAnswerType = "DOCUMENT" | "FAQ" | "DOCUMENT_WALLU_MENTIONED" | "HISTORY_SEARCH"

type FAQAnalytics = {
  total_answers: number
  total_no_votes: number
  total_yes_votes: number
  total_yes_votes_offset: number
  total_no_votes_offset: number
  no_votes_reasons: {
    [reason: string]: number
  }
}


export async function getGuildStats(guildId: string, since_days: number = 30): Promise<GuildAnalytics> {
  const data = await client.get("/guild-stats/" + guildId + "?since_days=" + since_days)
  return data.data
}
