export const HOME_WEBSITE_URL = 'https://wallubot.com/'
export const PLANS_WEBSITE_URL = 'https://wallubot.com/plans'

export const WALLU_INVITE_URL = "https://wallubot.com/invite"
export const SUPPORT_URL = "https://wallubot.com/discord"
export const GET_STARTED_TUTORIAL = "https://wallubot.com/tutorial"
export const GET_STARTED_TUTORIAL_EMBED = "https://wallubot.com/tutorial-embed"

export const PRIVACY_URL = "https://wallubot.com/privacy"
export const TERMS_URL = "https://wallubot.com/terms"
export const DOCUMENTATION_URL = "https://docs.wallubot.com/"

export const API_SPEC_URL = "https://api.wallubot.com/docs"
export const DEVELOPERS_URL = "https://wallubot.com/developers"
