import useAppContext from "../../context/useAppContext"
import { Divider } from "@mui/material"
import React from "react"
import { timestampInFutureFormat } from "../../util/util"

interface CreditsUsedBarProps {
  usageText: string
}

const settings = {
  limitKey: "gpt_credits_monthly",
  usageKey: "monthly_openai_tokens_used"
}

export function CreditsUsedBar({ usageText }: CreditsUsedBarProps) {

  const { usageLimit, usage, nextReset, reserveTokensLeft, percent } = useCreditUsage()
  const color = percent > 80 ? "red" : percent > 50 ? "yellow" : "green"

  const nextResetFormatted = nextReset ? timestampInFutureFormat(nextReset) : null

  // fancy bar
  return (
    <div className="">
      <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
        <div
          className={`text-xs text-gray-400 p-0.5 leading-none rounded-full h-4`}
          style={{ width: percent + '%', backgroundColor: color }}
        >
          <span className="font-bold">
            {percent}%
          </span>
        </div>
      </div>
      <div>
        <p className="text-sm text-gray-300 m-1">
          {usage} / {usageLimit} {usageText}
        </p>
        {(nextResetFormatted) && (
          <p className="text-xs text-gray-300 m-1">
            Usage resets {nextResetFormatted}
          </p>
        )}
      </div>
      <div className="my-2">
        <Divider/>
      </div>
      <div>
        <div className="text-base text-gray-300 m-1">
          <span className=" text-green-500 font-bold underline">{reserveTokensLeft}</span> reserve credits left
        </div>
        <p className="text-xs text-gray-400">
          Reserve credits are used when you exceed your monthly limit.
        </p>
      </div>
    </div>
  )

}

export function useCreditUsage() {
  const { config, plan, tokenData } = useAppContext()

  // @ts-ignore
  const usageLimit = plan?.limits?.[settings.limitKey]


  if (!config || !plan || !usageLimit) {
    return {
      usageLimit: 0,
      usage: 0,
      nextResetInDays: undefined,
      reserveTokensLeft: 0,
      percent: 0
    }
  }

  // @ts-ignore
  const usage = tokenData?.[settings.usageKey]
  const nextReset = tokenData?.next_monthly_reset && new Date(tokenData?.next_monthly_reset)

  const reserveTokensLeft = tokenData?.reserve_tokens_left

  const percent = Math.min(100, Math.round(usage / usageLimit * 100))

  return {
    usageLimit,
    usage,
    nextReset,
    reserveTokensLeft,
    percent
  }
}

export default CreditsUsedBar
