import React from "react"
import useAppContext from "../../context/useAppContext"
import { Button } from "@mui/material"
import NotOnThatServer from "../common/NotOnThatServer"

export default function SubscribeSuccessPage() {

  const { guild, plan } = useAppContext()
  const isOnServer = !!plan?.limits

  if (!isOnServer) {
    return <NotOnThatServer header={<div/>}/>
  }

  return (
    <div>
      <h2 className="text-2xl text-green-300 my-2">
        <span className="font-bold">Congratulations!</span>
        <br/>
        You have subscribed to a premium plan for {guild?.name}
        <p className="text-gray-400 text-sm mt-2">
          It may take a few minutes for the changes to take effect.
        </p>
      </h2>
      <div className="mt-12 flex flex-col items-center gap-6">
        <Button
          variant="contained"
          color="primary"
          href="/"
        >
          Back to panel
        </Button>
      </div>
    </div>
  )
}
