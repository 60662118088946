import React from "react"

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })

    const errorData = {
      message: error.message,
      name: error.name,
      stack: error.stack,
      componentStack: errorInfo.componentStack,
      location: window.location.href
    }

    if (!window.location.href.includes('localhost')) {
      fetch('https://api.wallubot.com/v1/panel-error', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ error: errorData }),
      }).catch(console.error)
    }
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <h2 className="text-gray-400 text-xs">Something went wrong loading this part. Please refresh the page.</h2>
        </div>
      )
    }
    return this.props.children
  }
}
