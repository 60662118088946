import React from "react"
import { IconButton, IconButtonProps } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'

const CloseButton = (props: IconButtonProps) => {

  return (
    <IconButton
      {...props}
      className="absolute top-0 right-0 text-gray-500"
      aria-label="close"
      size="large"
    >
      <CloseIcon/>
    </IconButton>
  )
}

export default CloseButton
