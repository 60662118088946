import React from 'react'
import { AppBar, IconButton, Toolbar } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import AccountMenu from "../AccountMenu"
import { useViewport } from "../../util/hooks/useViewport"

interface HeaderProps {
  handleDrawerToggle: () => any,
}

function Header({ handleDrawerToggle }: HeaderProps) {

  const { isMobile } = useViewport()

  return (
    <div>
      {isMobile && (
        <AppBar position="fixed">
          <Toolbar className="flex justify-between">
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={e => handleDrawerToggle()}
              size="large">
              <MenuIcon/>
            </IconButton>
            {isMobile && <AccountMenu/>}
          </Toolbar>
        </AppBar>
      )}
    </div>
  )
}

export default Header
