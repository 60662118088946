import React from "react"
import useAppContext from "../context/useAppContext"
import { Button } from "@mui/material"
import { BASE_URL } from "../config/axios"
import PremiumIcon from "@mui/icons-material/WorkspacePremium"
import { PLANS_WEBSITE_URL } from "../config/urls"
import { ServerPlan } from "../model/ServerPlan"

export const ManageSubscriptionButton = () => {

  const { guild, plan, billing } = useAppContext()

  const ManageStripe = ({ buttonText }) => (
    <Button
      disabled={!guild?.is_admin}
      title={guild?.is_admin ? undefined : "Only Discord server administrators can manage billing"}
      variant="contained"
      color="primary"
      href={getBillingPageURL(guild?.id)}
      style={{ backgroundColor: '#00d1b2' }}
      startIcon={<PremiumIcon className="text-violet-800"/>}
      target="_blank"
    >
      {buttonText}
    </Button>
  )

  if (isPremium(plan)) {
    return <ManageStripe buttonText="Manage Billing"/>
  }

  return (
    <div>
      <GoPremiumButton/>
      {/*Does not have a plan but is a stripe customer (e.g., cancelled)*/}
      {billing?.stripe && (
        <span className="ml-2">
          <ManageStripe buttonText="Old Invoices"/>
        </span>
      )}
    </div>
  )
}

export const GoPremiumButton = () => {

  const { billing } = useAppContext()

  const text = billing?.customer_exists ? "Renew Subscription!" : "Go Premium!"

  return (
    <Button
      className="hover:scale-110"
      href={PLANS_WEBSITE_URL}
      variant="contained"
      style={{ backgroundColor: '#00d1b2', transition: 'transform 0.3s' }}
      startIcon={<PremiumIcon className="text-violet-800"/>}
      target="_blank"
    >
      {text}
    </Button>
  )
}

export const isPremium = (plan: ServerPlan | undefined): boolean => {
  return !!(plan?.plan_type && ["PREMIUM", "CUSTOM_BOT", "CUSTOM", "BUSINESS"].includes(plan.plan_type))
}

export const getBillingPageURL = (guildId: string) => `${BASE_URL}/billing/${guildId}`
