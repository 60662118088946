import { useEffect, useState } from "react"

export const useViewport = () => {

  const [width, setWidth] = useState<number>(window.innerWidth)

  const handleWindowSizeChange = () => setWidth(window.innerWidth)

  useEffect(() => {
    setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768
  const isDesktop = width > 768

  return { isMobile, isDesktop }

}
