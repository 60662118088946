import React from "react"

interface AppIconAndNameProps {
  iconOnly?: boolean
}

export const AppIconAndName = ({ iconOnly }: AppIconAndNameProps) => {
  return (
    <div>
      <a href="/">
        <div className="flex items-center">
          <img src="/logo.png" alt="logo" className="inline-block w-12 h-12 mr-2 mb-2"/>
          {!iconOnly &&
            <span className="self-center text-2xl font-semibold whitespace-nowrap text-gray-500">Wallubot.com</span>
          }
        </div>
      </a>
    </div>
  )
}
