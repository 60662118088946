import { useEffect, useState } from "react"

const usePersistentState = <T>(id: string, initialState: T) => {

  const loadState = () => {
    if (typeof window === 'undefined') return initialState
    const val = window.localStorage.getItem(id)
    return val !== null ? JSON.parse(val) : initialState
  }

  const [state, setState] = useState<T>(() => loadState())

  useEffect(() => {
    window.localStorage.setItem(id, JSON.stringify(state))
  }, [id, state])

  return [state, setState] as const
}

export default usePersistentState
