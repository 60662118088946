import client from "../config/axios"

export type BotInstance = {
  name: string
  bot_token: string
  bot_status_type: BotInstanceType
  bot_status_text: string
  last_deployed_at: string
  last_modified_at: string
}

export type BotInstanceType = "PLAYING" | "WATCHING" | "NONE"

export async function getBotInstance(guildId: string): Promise<BotInstance> {
  const data = await client.get("/bot-config/" + guildId)
  return data.data
}

export async function updateBotInstance(guildId: string, botInstance: BotInstance): Promise<BotInstance> {
  const data = await client.post("/bot-config/" + guildId, botInstance)
  return data.data
}
