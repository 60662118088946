import Header from "../partials/Header"
import React, { useState } from "react"
import { Divider, Drawer } from "@mui/material"
import CloseButton from "../common/CloseButton"
import usePersistentState from "../../util/hooks/usePersistentState"
import { useViewport } from "../../util/hooks/useViewport"
import { AppIconAndName } from "../partials/AppIconAndName"
import { NavLink } from "react-router-dom"
import { AppContextInterface } from "../../context/AppContext"
import { PLANS_WEBSITE_URL } from "../../config/urls"
import useAppContext from "../../context/useAppContext"

type NavBarLink = {
    [category: string]: {
        [pageTitle: string]: {
            to: string
            icon: React.ReactElement
            disabled?: (ctx: AppContextInterface) => boolean
            title?: string
            category?: string
        }
    }
}

interface SideNavBarProps {
    links: NavBarLink
    miniLinks: { [key: string]: string }
    ctaEnabled: boolean
}

const SideNavBar = (props: SideNavBarProps) => {

  const { isMobile } = useViewport()

  const [navDrawerOpen, setNavDrawerOpen] = useState(false)
  const handleDrawerToggle = () => setNavDrawerOpen(!navDrawerOpen)

  return (
    <nav className="h-full">
      <Header handleDrawerToggle={handleDrawerToggle}/>
      <NavBar open={!isMobile || navDrawerOpen} handleDrawerToggle={handleDrawerToggle} {...props}/>
    </nav>
  )
}

interface NavBarProps extends SideNavBarProps {
    open: boolean
    handleDrawerToggle: () => void
}

const NavBar = ({ open, handleDrawerToggle, links, ctaEnabled, miniLinks }: NavBarProps) => {

  const { isMobile } = useViewport()
  const ctx = useAppContext()

  const DrawerContent = () => {
    return (
      <div className="h-full pt-24 px-4 w-72 overflow-y-auto bg-[#1B1F27] border-r border-gray-700 z-[100] relative">
        <div className="ml-4">
          <AppIconAndName/>
        </div>
        <Divider className="mt-4"/>
        <ul className="space-y-2 mt-12">
          {Object.entries(links).map(([category, pages]) => (
            <React.Fragment key={category}>
              <li className="text-gray-400 text-xs font-semibold uppercase tracking-wider mt-4 mb-2">
                {category}
              </li>
              {Object.entries(pages).map(([name, data]) => (
                <li key={name}>
                  <NavLink
                    target={data.to.startsWith("http") ? "_blank" : ""}
                    to={data.to}
                    onClick={e => {
                      if (data.disabled && data.disabled(ctx)) {
                        e.preventDefault()
                      } else if (open) {
                        handleDrawerToggle()
                      }
                    }}
                    title={data.title}
                    className={({ isActive }) => {
                      if (data.disabled && data.disabled(ctx)) {
                        return "flex items-center p-4 m-1 text-base font-normal rounded-lg text-gray-400 cursor-not-allowed"
                      }
                      return `flex items-center p-4 m-1 text-base font-normal rounded-lg text-white hover:bg-gray-700 ${isActive ? "bg-gray-700" : ""}`
                    }}
                  >
                    {data.icon}<span className="ml-3">{name}</span>
                  </NavLink>
                </li>
              ))}
            </React.Fragment>
          ))}
        </ul>
        {ctaEnabled && <CtaNotification/>}
        <div className="flex flex-col mt-12">
          {Object.entries(miniLinks).map(([name, data]) => (
            <a
              key={name}
              href={data}
              target="_blank"
              rel="noreferrer"
              className="my-2 text-sm"
            >
              <span className="text-blue-400 opacity-75">{name}</span>
            </a>
          ))}
        </div>
      </div>
    )
  }

  return isMobile ? (
    <Drawer
      variant="temporary"
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better performance on mobile
      }}
      open={open}
    >
      <div className="text-right mx-4">
        <CloseButton onClick={handleDrawerToggle}/>
      </div>
      {<DrawerContent/>}
    </Drawer>
  ) : (
    <div className="h-full">
      <DrawerContent/>
    </div>
  )
}

const CtaNotification = () => {

  const CTA_VERSION = 1 // Change this to show the notification again

  const [show, setShow] = usePersistentState(`sidenav-cta-show-v${CTA_VERSION}`, true)

  const cta = {
    title: "Premium",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quidem.",
    actionText: "Try our premium plans",
  }

  const onActionClick = () => {
    window.open(PLANS_WEBSITE_URL, "_blank")
  }

  return !show ? null : (
    <div id="dropdown-cta" className="p-4 mt-6 rounded-lg bg-blue-900" role="alert">
      <div className="flex items-center mb-3">
        <span
          className="text-sm font-semibold mr-2 px-2.5 py-0.5 rounded bg-orange-200 text-orange-900"
        >{cta.title}</span>
        <button
          onClick={() => setShow(false)}
          type="button"
          className="ml-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 focus:ring-blue-400 p-1 inline-flex h-6 w-6 bg-blue-900 text-blue-400 hover:bg-blue-800"
          aria-label="Close"
          title="Close"
        >
          <svg aria-hidden="true" className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <p className="mb-3 text-sm text-blue-400">
        {cta.text}
      </p>
      <a
        href="#"
        onClick={onActionClick}
        className="text-sm text-blue-400 hover:text-blue-300">
        {cta.actionText}
      </a>
    </div>
  )
}


export default SideNavBar
