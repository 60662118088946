import _ from 'lodash'

export function deepEquals(a: any, b: any, ignoreList: string[] = []) {
  a = _.omit(a, ignoreList)
  b = _.omit(b, ignoreList)
  return _.isEqual(a, b)
}

export const timestampAgoFormat = (timestamp: string) => {
  try {
    const created = new Date(timestamp)
    const diff = Math.round((Date.now() - created.getTime()) / 1000)
    const rtf1 = new Intl.RelativeTimeFormat()
    if (diff < 60) {
      return rtf1.format(-diff, 'second')
    }
    if (diff < 60 * 60) {
      return rtf1.format(-Math.round(diff / 60), 'minute')
    }
    if (diff < 60 * 60 * 24) {
      return rtf1.format(-Math.round(diff / 60 / 60), 'hour')
    }
    if (diff < 60 * 60 * 24 * 7) {
      return rtf1.format(-Math.round(diff / 60 / 60 / 24), 'day')
    }
    return created.toLocaleString()
  } catch (e) {
    console.error(e)
    return timestamp // fallback
  }
}

export const timestampInFutureFormat = (timestamp: Date) => {
  try {
    const diff = Math.round((timestamp.getTime() - Date.now()) / 1000)
    const rtf1 = new Intl.RelativeTimeFormat()
    if (diff < 60) {
      return rtf1.format(diff, 'second')
    }
    if (diff < 60 * 60) {
      return rtf1.format(Math.round(diff / 60), 'minute')
    }
    if (diff < 60 * 60 * 24) {
      return rtf1.format(Math.round(diff / 60 / 60), 'hour')
    }
    return rtf1.format(Math.round(diff / 60 / 60 / 24), 'day')
  } catch (e) {
    console.error(e)
    return timestamp.toLocaleString() // fallback
  }
}
